<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.work_program.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.work_program.norm') }}*</label>
                    <div v-if="validator.norm.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.norm.$error}">
					<div class="icon-left"><icon-clock /></div>
					<multiselect 
						v-model="work_program.norm"
						v-bind:class="{populate: work_program.norm != ''}"
						class="select-tags-tbf"
						:options="norms"
						:allow-empty="true"
						:show-labels="false"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.work_program.norm-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.work_program.norm_type') }}*</label>
                    <div v-if="validator.norm_type.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box bg-gray" v-bind:class="{has_error: validator.norm_type.$error}">
					<div class="icon-left"><icon-clock /></div>
					<multiselect 
						v-model="work_program.norm_type"
						v-bind:class="{populate: work_program.norm_type != ''}"
						class="select-tags-tbf"
						:options="normsType"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						disabled
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.work_program.norm_type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.work_program.daily_hours') }}*</label>
					<div v-if="validator.daily_hours.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.daily_hours.$error}">
					<div class="icon-left"><icon-clock /></div>
					<div class="icon-right" v-if="work_program.daily_hours != ''" @click.stop="work_program.daily_hours = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.work_program.daily_hours-ph')" class="input-text" v-model="work_program.daily_hours">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.work_program.vacation_days') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="work_program.vacation_days != ''" @click.stop="work_program.vacation_days = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.work_program.vacation_days-ph')" class="input-text" v-model="work_program.vacation_days">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconClock from '../../../../Icons/Clock'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconClock,
			IconCalendar,
			IconClose
		},
		props: {
			work_program: Object,
			validator: Object,
		    norms: Array,
            normsType: Array
		},
		data() {
			return {
				loaded: false,
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		}
	}
</script>